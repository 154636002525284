import * as ReactDOM from 'react-dom/client';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

import App from './App';
import { APP_DESCRIPTION, APP_TITLE } from './constants/default';
import ErrorPage from './pages/StaticPages/ErrorPage';

Bugsnag.start({
	apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
	plugins: [new BugsnagPluginReact()],
	// https://docs.bugsnag.com/platforms/javascript/#logging-breadcrumbs
	enabledBreadcrumbTypes: [],
});

BugsnagPerformance.start({
	apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);

const tagManagerArgs = {
	gtmId: process.env?.REACT_APP_GTM,
};

// @ts-ignore
const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

root.render(
	<>
		<Helmet>
			<meta name="viewport" content="initial-scale=1, width=device-width" />
			<meta name="description" content={APP_DESCRIPTION} />

			<title>{APP_TITLE}</title>

			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
			<link
				rel="stylesheet"
				href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&family=Syne:wght@600&display=swap"
			/>
			{/* Add the Google Tag Manager script */}
			{process.env?.REACT_APP_ENV === 'production' && TagManager.initialize(tagManagerArgs)}
		</Helmet>

		<BugsnagErrorBoundary>
			<ErrorBoundary fallback={<ErrorPage />}>
				<App />
			</ErrorBoundary>
		</BugsnagErrorBoundary>
	</>,
);
