import React, { JSX } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import routesPaths from 'routes/paths';

import useSliceAuthentication from 'store/hooks/useSliceAuthentication';

const { SIGN_IN } = routesPaths;

const AuthGuard: React.FC = (): JSX.Element | null => {
	const { isAuthenticated } = useSliceAuthentication();

	if (isAuthenticated) {
		return <Outlet />;
	}

	return <Navigate to={SIGN_IN} />;
};

export default AuthGuard;
