import { createApi } from '@reduxjs/toolkit/query/react';

import handleFetchAdminTextsData from 'store/dataHandlers/handleFetchAdminTextsData';
import { ResponseAdminTexts, AdminTexts } from 'store/types/typesAdminTexts';
import handleResponseError from 'store/dataHandlers/handleResponseError';

import { baseQueryWithReAuth, origin, pathnameApiAdminTexts } from './common';

export const apiAdminTexts = createApi({
	reducerPath: 'apiAdminTexts',
	baseQuery: baseQueryWithReAuth,

	endpoints(build) {
		return {
			fetchAdminTexts: build.query<AdminTexts, void>({
				query: () => {
					return {
						method: 'GET',
						url: `${origin}${pathnameApiAdminTexts}`,
					};
				},

				transformResponse: (response: ResponseAdminTexts) => {
					return handleFetchAdminTextsData(response);
				},

				async onQueryStarted(args, { dispatch, queryFulfilled }) {
					try {
						await queryFulfilled;
					} catch (error) {
						handleResponseError(dispatch, error, 'fetchAdminTexts');
					}
				},
			}),
		};
	},
});

// Auto-generated React hooks
export const { useFetchAdminTextsQuery } = apiAdminTexts;
