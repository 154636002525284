import { ProductInfo, ProductInfoResponse } from 'store/types/typesProducts';
import commonUtils from 'store/common/utils';
import { formatPrice } from 'utils/commonUtils';

const { valueToString, valueToNumber } = commonUtils;

const handleFetchProductInfoData = (response: ProductInfoResponse): ProductInfo => {
	const currency = valueToString(response?.currency);
	const responseAmount = valueToNumber(response?.unit_amount);

	const amount =
		responseAmount > 0
			? formatPrice(responseAmount / 100, currency)
			: formatPrice(responseAmount, currency);

	return {
		amount,
	};
};

export default handleFetchProductInfoData;
