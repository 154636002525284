import { PromoCodeDetails, ResponsePromotionCodeObject } from 'store/types/typesProducts';
import commonUtils from 'store/common/utils';

const { valueToString, valueToNumber } = commonUtils;

const handleFetchPromoCodeData = (
	response: ResponsePromotionCodeObject,
): PromoCodeDetails | null => {
	if (Object.keys(response).length > 0) {
		return {
			code: valueToString(response?.code, 'Promo code'),
			discountPercent: valueToNumber(response?.coupon?.percent_off),
		};
	}

	return null;
};

export default handleFetchPromoCodeData;
