import { PaymentIntent as PaymentIntentResponse } from '@stripe/stripe-js';
import commonUtils from 'store/common/utils';
import { PaymentIntent } from 'store/types/typesPayments';
import { formatPrice } from 'utils/commonUtils';

const { valueToString, valueToNumber } = commonUtils;

const handleFetchPaymentIntent = (response: PaymentIntentResponse): PaymentIntent => {
	let amount = valueToNumber(response?.amount);
	const currency = valueToString(response?.currency, 'usd');

	if (amount) {
		// `amount` last two digits are cents
		amount /= 100;
	}

	return {
		formattedPrice: formatPrice(amount, currency),
		clientSecret: valueToString(response.client_secret),
	};
};

export default handleFetchPaymentIntent;
