import commonUtils from 'store/common/utils';
import { ResponseAdminTexts, AdminTexts } from 'store/types/typesAdminTexts';

const { valueToString } = commonUtils;

const handleFetchAdminTextsData = (response: ResponseAdminTexts): AdminTexts => {
	const items: AdminTexts = {};

	if (Array.isArray(response)) {
		response.forEach((item) => {
			if (item.key) {
				items[item.key] = valueToString(item?.value);
			}
		});
	}

	return items;
};

export default handleFetchAdminTextsData;
