import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { StoreState } from 'store';
import { generationToolsKeys } from 'store/common/keys';
import { ProjectOpenedInit } from 'store/types/typesProjects';
import {
	setOpenedProject,
	removeOpenedProjectById,
	updateProjectTitle,
	setGenerationTool,
	removeOpenedProjectTab,
	addOpenedProjectTab,
	updateOpenedProjectTabTitle,
	updateOpenedProjectTabGenerationToolById,
} from './sliceOpenedProjects';

export const syncOpenedProjectsToTabs = createListenerMiddleware();

syncOpenedProjectsToTabs.startListening({
	matcher: isAnyOf(
		setOpenedProject,
		removeOpenedProjectById,
		updateProjectTitle,
		setGenerationTool,
	),
	effect: async (action, listenerApi) => {
		const state = listenerApi.getState() as StoreState;
		const { openedProjectTabs, openedProjects, currentProjectId } = state.sliceOpenedProjects;

		if (action.type === removeOpenedProjectById.type) {
			const projectId = action.payload as string;
			const isTabAlreadyOpen = Boolean(
				openedProjectTabs.find((tab) => tab.projectId === projectId),
			);

			if (!isTabAlreadyOpen) return;

			listenerApi.dispatch(removeOpenedProjectTab(projectId));
		}

		if (action.type === setOpenedProject.type) {
			const { projectId, projectTitle, currentGenerationTool } =
				action.payload as ProjectOpenedInit;
			const isTabAlreadyOpen = Boolean(
				openedProjectTabs.find((tab) => tab.projectId === projectId),
			);

			if (isTabAlreadyOpen) {
				listenerApi.dispatch(
					updateOpenedProjectTabGenerationToolById({
						projectId,
						generationTool: currentGenerationTool,
					}),
				);
				return;
			}

			listenerApi.dispatch(
				addOpenedProjectTab({ projectId, projectTitle, currentGenerationTool }),
			);
		}

		if (action.type === updateProjectTitle.type) {
			listenerApi.dispatch(
				updateOpenedProjectTabTitle(
					action.payload as { projectId: string; projectTitle: string },
				),
			);
		}

		if (action.type === setGenerationTool.type) {
			const currentOpenedProject = openedProjects[currentProjectId];

			if (!currentOpenedProject) return;

			listenerApi.dispatch(
				updateOpenedProjectTabGenerationToolById({
					projectId: currentProjectId,
					generationTool:
						action.payload as (typeof generationToolsKeys)[keyof typeof generationToolsKeys],
				}),
			);
		}
	},
});
