import { createApi } from '@reduxjs/toolkit/query/react';

import handleResponseError from 'store/dataHandlers/handleResponseError';

import handleFetchInvoicesData from 'store/dataHandlers/handleFetchInvoicesData';
import { Invoices, ResponseInvoices } from 'store/types/typesInvoices';
import {
	baseQueryWithReAuth,
	invalidationTags,
	keepUnusedDataFor,
	origin,
	pathnameApiPayments,
} from './common';

const { clearAllFetchedInvoices } = invalidationTags;

export const apiPayments = createApi({
	reducerPath: 'apiPayments',
	baseQuery: baseQueryWithReAuth,
	keepUnusedDataFor,

	tagTypes: [clearAllFetchedInvoices],

	endpoints(build) {
		return {
			fetchInvoices: build.query<Invoices, void>({
				query: () => {
					return {
						method: 'GET',
						url: `${origin}${pathnameApiPayments}/invoices`,
					};
				},

				transformResponse(response: ResponseInvoices) {
					return handleFetchInvoicesData(response);
				},

				async onQueryStarted(args, { dispatch, queryFulfilled }) {
					try {
						await queryFulfilled;
					} catch (error) {
						handleResponseError(dispatch, error, 'fetchInvoices');
					}
				},

				providesTags: [clearAllFetchedInvoices],
			}),
		};
	},
});

// Auto-generated React hooks
export const { useFetchInvoicesQuery, useLazyFetchInvoicesQuery } = apiPayments;
